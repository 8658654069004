
import { Component, Vue } from 'vue-property-decorator';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import UpIcon from 'vue-material-design-icons/ChevronUp.vue';
import DownIcon from 'vue-material-design-icons/ChevronDown.vue';
import SearchIcon from 'vue-material-design-icons/Magnify.vue';
import { v4 as uuid } from 'uuid';
import './Admin.scss';
import ENGRCourses from '@/shared/courses';
import { post } from '@/shared/requests';
import PagedTable from '@/components/PagedTable.vue';

type Question = {
  type: 'longform' | 'selectone' | 'selectmany' | 'truefalse';
  question: string;
  options: string[];
};

@Component({
  components: {
    CloseIcon,
    UpIcon,
    DownIcon,
    SearchIcon,
    PagedTable,
  },
})
export default class Admin extends Vue {
  journal: {
    promptOrder: string[];
    name: string;
    courses: Record<string, boolean>;
    byId: Record<string, Question>;
    dueAtDay: string;
    dueAtTime: string;
    error: string;
  } = {
    promptOrder: [],
    byId: {},
    courses: {},
    name: '',
    dueAtDay: '',
    dueAtTime: '',
    error: '',
  };

  showModal = {
    loadUsers: false,
  };

  journalOverlay = false;

  timeRegex = /:\d{2}(?=[^:])/;

  allCourses = ENGRCourses;

  responseId = '';

  csvFile: File | null = null;

  constructor() {
    super();

    const currentSeason = new Date().getMonth() > 5 ? 'AU' : 'SP';
    Object.keys(ENGRCourses).forEach(courseId => {
      if (ENGRCourses[courseId as keyof typeof ENGRCourses].season.includes(currentSeason)) {
        console.log(courseId)
        this.$set(this.journal.courses, courseId, false);
      }
    });
  }

  addQuestion() {
    const id = uuid();
    this.journal.promptOrder.push(id);
    this.$set(this.journal.byId, id, { type: 'longform', question: '', options: [] });
  }

  removeQuestion(id: string) {
    this.journal.promptOrder.splice(this.journal.promptOrder.indexOf(id), 1);
    this.$delete(this.journal.byId, id);
  }

  moveUp(id: string) {
    const index = this.journal.promptOrder.indexOf(id);
    if (index > 0) {
      this.journal.promptOrder.splice(index, 1);
      this.journal.promptOrder.splice(index - 1, 0, id);
    }
  }

  moveDown(id: string) {
    const index = this.journal.promptOrder.indexOf(id);
    if (index + 1 < this.journal.promptOrder.length) {
      this.journal.promptOrder.splice(index, 1);
      this.journal.promptOrder.splice(index + 1, 0, id);
    }
  }

  addOption(id: string) {
    this.$set(this.journal.byId[id], 'options', this.journal.byId[id].options.concat(['']));
  }

  removeOption(id: string, index: number) {
    const opts = Array.prototype.slice.call(this.journal.byId[id].options);
    opts.splice(index, 1);
    this.$set(this.journal.byId[id], 'options', opts);
  }

  async distribute() {
    const dueAt = new Date(`${this.journal.dueAtDay} ${this.journal.dueAtTime}`);

    if (!this.journal.dueAtDay) {
      this.$set(this.journal, 'error', 'Missing the due date');
      return;
    }

    if (!this.journal.dueAtTime) {
      this.$set(this.journal, 'error', 'Missing the due date time');
      return;
    }

    if (dueAt < new Date()) {
      this.$set(this.journal, 'error', 'Set a due date in the future');
      return;
    }

    if (!this.journal.name || this.journal.name.length < 3) {
      this.$set(this.journal, 'error', 'Set a journal name');
      return;
    }

    const courses = Object.keys(this.journal.courses).reduce((a, b) => {
      if (this.journal.courses[b]) a.push(b);
      return a;
    }, [] as string[]);

    if (courses.length === 0) {
      this.$set(this.journal, 'error', 'Select at least one course');
      return;
    }

    if (this.journal.promptOrder.length === 0) {
      this.$set(this.journal, 'error', 'Add at least one question');
      return;
    }

    try {
      this.journal.promptOrder.forEach(promptId => {
        const prompt = this.journal.byId[promptId];

        if (!prompt.question || prompt.question.length < 3) {
          throw new Error('Make sure all questions have text.');
        }

        if (prompt.question.length > 300) {
          throw new Error(`"${prompt.question.substr(0, 20).trim()}..." is too long.`);
        }

        if (prompt.type === 'selectone' || prompt.type === 'selectmany') {
          if (prompt.options.length < 2) {
            throw new Error('Multiple choice questions must have at least two options.');
          } else if (prompt.options.length > 20) {
            throw new Error('Only up to twenty options can be specified at for one question');
          }
          prompt.options.forEach(opt => {
            if (opt.length > 100) {
              throw new Error(`"${opt.substr(0, 20).trim()}..." is too long.`);
            }
          });
        }
      });
    } catch (error) {
      const err = error as Error;
      this.$set(this.journal, 'error', err.message);
      return;
    }

    this.$set(this, 'journalOverlay', true);
    const res = await post('/admin/journals/create', {
      name: this.journal.name,
      dueAt: Math.round(dueAt.getTime() / 1000),
      courses,
      prompts: this.journal.promptOrder.map(promptId => this.journal.byId[promptId]),
    });
    this.$set(this, 'journalOverlay', false);

    if (res.success) {
      this.$bvToast.toast('Journal successfully added.', {
        title: 'Success',
        autoHideDelay: 5000,
      });

      this.$set(this, 'journal', {
        promptOrder: [],
        byId: {},
        courses: this.journal.courses,
        name: '',
        dueAtDay: '',
        dueAtTime: '',
        error: '',
      });
    } else {
      this.$set(this.journal, 'error', res.error?.message ?? 'Unable to create the journal.');
      this.$bvToast.toast('Unable to create the journal.', {
        title: 'Error',
        autoHideDelay: 5000,
      });
    }
  }

  journalFields: ({ key: string; sortable: boolean; label?: string } | string)[] = [
    'name',
    'dueAt',
    'courses',
  ];

  async fetchJournalPage(
    offsetKey?: string,
  ): Promise<{ offsetKey: string; items: Record<string, any>[] }> {
    const { data, error, success } = await post('/admin/journals', {
      offsetKey,
    });

    if (success && data) {
      return { offsetKey: data.offsetKey, items: data.journals };
    }

    if (error) {
      throw error;
    }

    throw new Error('Unable to get journals page');
  }

  userFields: ({ key: string; sortable?: boolean; label?: string } | string)[] = [
    'email',
    'role',
    'isAdmin',
  ];

  async fetchUserPage(
    offsetKey?: string,
  ): Promise<{ offsetKey: string; items: Record<string, any>[] }> {
    const { data, error, success } = await post('/admin/users', {
      offsetKey,
    });

    if (success && data) {
      return { offsetKey: data.offsetKey, items: data.users };
    }

    if (error) {
      throw error;
    }

    throw new Error('Unable to get users page');
  }

  disableUpload = false;

  async uploadUsers() {
    if (!this.csvFile) {
      return;
    }

    this.$set(this, 'disableUpload', true);

    console.log('uploading users');

    const reader = new FileReader();
    reader.readAsBinaryString(this.csvFile);
    reader.addEventListener('load', async ev => {
      const result = ev.target?.result as string | null | undefined;

      if (result) {
        const changes = result
          .trim()
          .split('\n')
          .slice(1)
          .map(a => a.split(','))
          .map(([nameNumber, action, role, course, section, semester]) => ({
            email: `${nameNumber.toLowerCase()}@osu.edu`,
            action: action === 'Add or Modify' ? 'update' : 'remove',
            role,
            course,
            section: String(section),
            semester: semester
              .toUpperCase()
              .trim()
              .replace('\r', ''),
          }));
        console.log(changes);

        const { success, error } = await post('/admin/users/create/batch', { changes });

        if (success) {
          console.log('success');
        } else if (error) {
          console.error(error);
          this.$set(this, 'error', 'Unable to add users');
        }
      }

      this.$set(this, 'disableUpload', false);
      // this.$set(this.showModal, 'loadUsers', false);
    });
  }

  async completionReport() {
    const { success, error, data } = await post('/admin/reports/completion', {});

    if (!success || error) {
      console.error('Report generation failed', error);
      return;
    }

    const { report, journals } = data as {
      report: {
        classId: string;
        instructors: { course: string; email: string; section: string; classId: string }[];
        students: {
          email: string;
          completions: {
            id: string;
            userId: string;
            journalId: string;
            createdAt: number;
          }[];
        }[];
      }[];
      journals: Record<
        string,
        {
          id: string;
          name: string;
          dueAt: number;
        }
      >;
    };

    // console.log(report);

    const lines: string[] = [];

    report.forEach(({ instructors, students }) => {
      lines.push(`"${instructors[0].course}","${instructors[0].section}"`);
      lines.push(instructors.map(i => `"${i.email}"`).join(','));
      lines.push('');

      students
        .filter(a => !!a.email)
        .sort((a, b) => a.email.localeCompare(b.email))
        .forEach(({ email, completions }) => {
          const journalNames = completions
            .map(completion => journals[completion.journalId])
            .sort((a, b) => (a.dueAt > b.dueAt ? 1 : -1))
            .map(j => `"${j.name}"`)
            .join(',');

          const lateJournals = completions.reduce(
            (
              res: [
                {
                  id: string;
                  name: string;
                  dueAt: number;
                },
                number,
              ][],
              completion,
            ) => {
              if (completion.createdAt > journals[completion.journalId].dueAt) {
                res.push([journals[completion.journalId], completion.createdAt]);
              }
              return res;
            },
            [],
          );

          const lateJournalNames = lateJournals
            .sort((a, b) => (a[0].dueAt > b[0].dueAt ? 1 : -1))
            .map(
              lj =>
                `"${lj[0].name} (${((lj[1] - lj[0].dueAt) / (3600 * 24)).toFixed(2)} days late)"`,
            )
            .join(',');

          lines.push(`"${email}",${completions.length},journal(s) completed,${journalNames}`);
          lines.push(`"",${lateJournals.length},journal(s) late,${lateJournalNames}`);
        });

      lines.push('');
      lines.push('');
    });

    const blob = new Blob([lines.join('\n')], { type: 'text/csv' });
    const downloadURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadURL;
    a.download = 'Journal Completion Report.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(downloadURL);
  }

  async reverseLookup() {
    
  }
}
