
import { Component, Prop, Vue } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';

const timeRegex = /:\d{2}(?=[^:])/;

@Component
export default class PagedTable extends Vue {
  id = uuid();

  timeRegex = timeRegex;

  @Prop() fields!: { key: string; sortable: boolean; label?: string }[];

  @Prop() fetchPage!: (
    page?: string,
  ) => Promise<{ offsetKey: string; items: Record<string, any>[] }>;

  page = 0;

  items: Record<string, any>[] = [];

  itemsHistory: Record<string, Record<string, any>[]> = {};

  keyHistory: Record<string, string> = {};

  loading = true;

  error = '';

  mounted() {
    this.load(this.page);
  }

  async load(page: number) {
    if (this.itemsHistory[page]) {
      this.$set(this, 'items', this.itemsHistory[page]);
      return;
    }

    this.$set(this, 'loading', true);

    try {
      const { offsetKey, items } = await this.fetchPage(this.keyHistory[page - 1]);

      this.$set(this, 'keyHistory', { ...this.keyHistory, [page]: offsetKey });
      this.$set(this, 'itemsHistory', { ...this.itemsHistory, [page]: items });
      this.$set(this, 'items', items);
    } catch (error) {
      console.error(error);
      this.$set(this, 'error', 'Unable to fetch items.');
    }

    this.$set(this, 'loading', false);
  }

  async next() {
    if (this.loading || this.items.length !== 10) {
      return;
    }

    const page = this.page + 1;
    await this.load(page);
    this.$set(this, 'page', page);
  }

  async back() {
    if (this.loading || this.page === 0) {
      return;
    }

    const page = this.page - 1;
    await this.load(page);
    this.$set(this, 'page', page);
  }
}
